import { render, staticRenderFns } from "./GDPRBanner.vue?vue&type=template&id=07000e30&scoped=true"
import script from "./GDPRBanner.vue?vue&type=script&lang=js"
export * from "./GDPRBanner.vue?vue&type=script&lang=js"
import style0 from "./GDPRBanner.vue?vue&type=style&index=0&id=07000e30&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07000e30",
  null
  
)

export default component.exports